import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import {
  Calendar as CalendarIcon,
  Clock as ClockIcon,
  MapPin as MapPinIcon,
  ShoppingCart as ShoppingCartIcon
} from "react-feather"
//
import { Layout } from "../components/layout"
import { Container } from "../storybook/components/misc/Layouts"
import { renderComponent } from "../utils/components"
import { getTags } from "../utils/seo"
import InfoGridBlock from "../storybook/components/blocks/InfoGridBlock"
import { NavigationLink } from "../utils/navigation"
import { ImageFluid } from "../utils/image"
import Breadcrumbs from "../components/layout/breadcrumb"
import TextBlock from "../storybook/components/blocks/TextBlock"

const FooterContainer = styled.div`
  ${tw`mt-8 mx-auto relative`}
`

const BodyContainer = styled.div`
  ${tw`py-8 mx-auto max-w-screen-lg`}
  h4 {
    ${tw`text-2xl`}
  }
`

const SmallCardContainer = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start rounded-xl text-center w-full md:w-auto sm:text-left h-full py-2`}
  svg {
    ${tw`w-full sm:w-auto`}
  }
`

const StyledNavigationLink = styled(NavigationLink)`
  ${tw`mt-4 flex flex-grow justify-center w-full sm:w-auto text-sm sm:text-base py-2 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:ring shadow text-gray-100 hocus:text-gray-300 bg-secondary-500 hocus:bg-secondary-700 uppercase`}
`

const StyledImageFluid = styled(ImageFluid)`
  .gatsby-image-wrapper {
    width: 100% !important;
    max-width: 100%;
  }
`

const SmallCard = ({ title, text, icon }) => (
  <SmallCardContainer>
    <div>
      <div tw="block sm:flex sm:flex-row">
        <div tw="text-center w-full sm:w-auto mx-auto sm:mx-0 p-2 sm:p-0">{icon}</div>
        <div tw="ml-0 sm:ml-1 tracking-wide font-normal leading-none">{title}</div>
      </div>
      <div tw="mt-1 font-medium text-secondary-100 leading-loose">{text}</div>
    </div>
  </SmallCardContainer>
)

const CursoDetails = ({ duracao, horario, data, local, investimento }) => (
  <div tw="mr-4 mt-4 px-4 py-2 rounded-lg text-gray-800 border-gray-100 px-4 py-2 w-auto sm:flex-row items-center sm:items-start text-left">
    {duracao && <SmallCard title="Duração" text={duracao} icon={<ClockIcon size={16} />} />}
    {horario && <SmallCard title="Horário" text={horario} icon={<CalendarIcon size={16} />} />}
    {data && <SmallCard title="Data" text={data} icon={<CalendarIcon size={16} />} />}
    {local && <SmallCard title="Local" text={local} icon={<MapPinIcon size={16} />} />}
    {investimento && <SmallCard title="Investimento" text={investimento} icon={<ShoppingCartIcon size={16} />} />}
  </div>
)

const CategoriaCurso = ({ data }) => {
  const { contentfulFormacaoCategoria, contentfulSite, allContentfulFormacaoCategoria, allContentfulFormacaoCurso } =
    data
  const { titulo, descricao, imagem, imagemDeFundo } = contentfulFormacaoCategoria

  const tags = getTags({ titulo, sumario: descricao, imagem, site: contentfulSite })

  const formacaoSlug = contentfulSite?.paginaFormacao?.slug

  const hero = {
    __typename: "ContentfulHeroVideoOuImagem",
    titulo1: titulo,
    titulo2: "Formação",
    imagemDeFundo
  }

  const outrasCategorias = allContentfulFormacaoCategoria?.edges
    ?.map((edge) => edge?.node)
    ?.map((categoria) => {
      return {
        title: categoria.titulo,
        description: categoria.descricao?.descricao,
        image: categoria?.imagem ? <StyledImageFluid image={categoria.imagem} /> : null,
        cta: (
          <StyledNavigationLink
            navigation={{
              id: categoria.id,
              paginaInterna: { id: categoria.id, slug: `formacao/${categoria.slug}` },
              titulo: "ver mais"
            }}
          />
        )
      }
    })

  const cursos = allContentfulFormacaoCurso?.edges
    ?.map((edge) => edge.node)
    ?.map((curso) => {
      return {
        title: <div css={[`height: 7rem`]}>{curso.titulo}</div>,
        description: <CursoDetails {...curso} />,
        cta: (
          <StyledNavigationLink
            navigation={{ id: curso.id, paginaInterna: { id: curso.id, slug: curso.slug }, titulo: "ver mais" }}
          />
        )
      }
    })

  return (
    <Layout tags={tags} heroType={hero.__typename}>
      {renderComponent(hero)}
      <Container tw="z-30" id="main-section">
        <Breadcrumbs items={[{ to: `/${formacaoSlug}`, title: "Formação" }, { title: titulo }]} />
        <BodyContainer>
          <p tw="mt-4 mb-0 text-xl max-w-screen-sm mx-auto text-center">{descricao?.descricao}</p>
          {cursos?.length > 0 ? (
            <InfoGridBlock heading="Ofertas Formativas" cards={cursos} />
          ) : (
            <div tw="mx-auto text-center pt-4 pb-8"><TextBlock text={<h2>Nenhuma oferta formativa disponível no momento.</h2>} /></div>
          )}
        </BodyContainer>
        <FooterContainer>
          <InfoGridBlock heading="Outras opções formativas" solidBg cards={outrasCategorias} imageTop />
        </FooterContainer>
      </Container>
    </Layout>
  )
}

export default CategoriaCurso

export const pageQuery = graphql`
  query ($id: String!, $locale: String!) {
    contentfulFormacaoCategoria(id: { eq: $id }, node_locale: { eq: $locale }) {
      id
      titulo
      descricao {
        descricao
      }
      imagem {
        file {
          url
        }
        gatsbyImageData(layout: FIXED, width: 1200, height: 600, resizingBehavior: CROP, quality: 80)
      }
      imagemDeFundo: imagem {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    allContentfulFormacaoCategoria(filter: { id: { ne: $id }, node_locale: { eq: $locale } }) {
      edges {
        node {
          id
          titulo
          slug
          descricao {
            descricao
          }
          imagem {
            gatsbyImageData(layout: CONSTRAINED, width: 300, height: 200, resizingBehavior: FILL)
          }
        }
      }
    }

    allContentfulFormacaoCurso(filter: { categoria: { id: { eq: $id } } }) {
      edges {
        node {
          slug
          titulo
          horario
          duracao
          data
          local
          investimento
          id
        }
      }
    }

    contentfulSite(node_locale: { eq: $locale }) {
      ...FragmentContentfulSite
    }

    site {
      ...FragmentSite
    }
  }
`
