import React from "react"
import tw, { styled } from "twin.macro"
import { Link } from "gatsby"
import { ChevronRight as ChevronRightIcon } from "react-feather"
//
import { ContentWithPadding } from "../../storybook/components/misc/Layouts"

const StyledLink = styled(Link)`
  ${tw`font-bold text-secondary-300 border-none pb-1 hocus:text-secondary-500 hocus:border-solid hocus:border-b-2 hocus:border-secondary-300`}
`

const Breadcrumbs = ({ items = [] }) => {
  return (
    <ContentWithPadding>
      <nav tw="w-full text-xs sm:text-sm">
        <ol tw="flex flex-col sm:flex-row">
          <li>
            <StyledLink to="/">Início</StyledLink>
          </li>
          {items?.map((item, idx) => (
            <React.Fragment key={`${item.to}-${idx}`}>
              {idx < items?.length && (
                <li tw="p-1 hidden sm:inline">
                  <ChevronRightIcon size={16} />
                </li>
              )}
              <li>{item?.to ? <StyledLink to={item.to}>{item.title}</StyledLink> : <>{item.title}</>}</li>
            </React.Fragment>
          ))}
        </ol>
      </nav>
    </ContentWithPadding>
  )
}

export default Breadcrumbs
